import React  from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';

export default function DateCreated(props) {
  const { offer } = props;

  return (
    <TextField label="Date Created">
      {moment(offer.data.createdAt).format('MMM D, YYYY')} · {`${moment().diff(offer.data.createdAt, 'days')} days ago`}
    </TextField>
  );
}
