import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(offer) {
  const missingFields = [];

  const offerParticipants = useOfferTags(offer, TagTypes.Participant);
  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  if (
    offerParticipants.state === PayloadStates.FETCHING ||
    offerInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!offer.data.activityId) {
    missingFields.push({
      field: 'activityId',
      name: 'activity',
      label: 'Activity'
    });
  }

  if (offerParticipants.data.length === 0) {
    missingFields.push({
      field: 'participants',
      name: 'participants',
      label: 'Participants'
    });
  }

  // if (
  //   _.find(offerParticipants.data, m => m.data.name === 'Students') &&
  //   !offer.data.students
  // ) {
  //   missingFields.push({
  //     field: 'students',
  //     name: 'Student Count',
  //     label: 'Student Count'
  //   });
  // }

  if (!offer.data.description) {
    missingFields.push({
      field: 'description',
      name: 'Summary',
      label: 'Summary'
    });
  }

  if (
    offer.data.specificDate === 'Yes' &&
    !offer.data.date
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date',
      label: 'Date'
    });
  }

  if (
    offer.data.specificDate === 'No' && (
      !offer.data.startDate ||
      !offer.data.endDate
    )
  ) {
    missingFields.push({
      field: 'date',
      name: 'Date Range',
      label: 'Date Range'
    });
  }

  if (offerInteractionTypes.data.length === 0) {
    missingFields.push({
      field: 'offerInteractionTypes',
      name: 'Location',
      label: 'Location'
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
