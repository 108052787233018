import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';

// Offers
import starredOffers from '../../offers-filtered/starred/useQuery';
import newOffers from '../../offers-filtered/new/useQuery';
import draftOffers from '../../offers-filtered/draft/useQuery.last4weeks';
import activeOffers from '../../offers-filtered/active/useQuery';
import schoolYearOffers from '../../offers-filtered/school-year/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/offers` },
    // { name: 'All (Table)', href: `/offers` },
    { name: 'Starred', href: `/offers/starred`, count: useGetCount(starredOffers) },
    { name: 'New', href: `/offers/new`, count: useGetCount(newOffers), highlight: true },
    { name: 'Incomplete', href: `/offers/draft`, count: useGetCount(draftOffers) },
    { name: 'Active Offers', href: `/offers/active`, count: useGetCount(activeOffers) },
    { name: 'All Offers this School Year', href: `/offers/school-year`, count: useGetCount(schoolYearOffers) }
  ];
}
