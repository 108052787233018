import React from 'react';
import { useConnect } from '@lore/query-connect';
import Activity from './Activity';

export default function Header(props) {
  const { offer } = props;

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  return (
    <div className="flex flex-col space-y-2">
      {/*<Activity activity={activity} />*/}
      <div className="space-y-0.5">
        <div className="text-lg font-semibold text-cs-gray-900">
          {offer.data.title || activity.data.name}
        </div>
        <div className="text-base text-cs-gray-500 line-clamp-3">
          {offer.data.description || activity.data.description}
        </div>
      </div>
    </div>
  );
}
