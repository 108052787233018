import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Offer from '../../offers/Offer';
import DateCreated from '../../offers/_fields/DateCreated';
import MissingFields from '../../offers/_fields/MissingFields';
import useQuery from './useQuery.last4weeks';
import CardChildrenWrapper from '../../_common_results/CardChildrenWrapper';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Offers']}
      title="Incomplete Offers"
      description={(
        <span>
          These are offers that a partner started but did not complete the minimum information
          to be shown publicly on the platform. CommunityShare recommends a quick call or email
          to remind them that you are here to help them talk through their ideas and needs. Choosing
          the offer from their home page will highlight exactly which fields are missing.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(offer) {
        return (
          <Offer key={offer.id} offer={offer}>
            <CardChildrenWrapper>
              <div className="grid grid-cols-2 gap-4 w-full">
                <DateCreated offer={offer} />
                <MissingFields offer={offer} />
              </div>
            </CardChildrenWrapper>
          </Offer>
        );
      }}
    />
  );
}
