import React  from 'react';

export default function Site(props) {
  const { userSite } = props;

  return (
    <div className="flex justify-between text-sm space-x-1">
      <div className="text-cs-gray-700 font-semiboldx">
        {userSite.data.site.name}
      </div>
      <div className="text-cs-gray-500">
        {userSite.data.distance}
      </div>
    </div>
  );
}
