import React  from 'react';
import { PayloadStates } from '@lore/utils';

export default function Count(props) {
  const { userSites } = props;

  return (
    <div className="flex justify-between text-sm space-x-1">
      <div className="text-cs-gray-700 font-semibold">
        Sites ({userSites.state === PayloadStates.FETCHING ? '...' : userSites.data.length})
      </div>
      <div className="text-cs-gray-500">
        Miles
      </div>
    </div>
  );
}
