import React, { useState } from 'react';
import moment from 'moment-timezone';
import TextField from '../../projects/_fields/TextField';
import { useConnect } from '@lore/query-connect';
import Site from './Site';
import useNetwork from '../../../hooks/useNetwork';
import Count from './Count';

export default function Sites(props) {
  const { user } = props;

  const network = useNetwork();

  const userSites = useConnect('userSite.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          'site.networks.id': network?.id,
        },
        site: {}
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'distance asc'
    }
  });

  const [expanded, setExpanded] = useState(false);

  const MIN_COUNT = 3;

  const _data = expanded ? userSites.data : userSites.data.slice(0, MIN_COUNT);

  return (
    <div className="space-y-2">
      <Count userSites={userSites} />
      {_data.map(function(userSite) {
        return (
          <Site key={userSite.id} userSite={userSite} />
        );
      })}
      {userSites.data.length > MIN_COUNT && (
        <div className="text-blue-500 text-sm hover:text-blue-400 cursor-pointer" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show less' : 'Show more'}
        </div>
      )}
    </div>
  );
}
